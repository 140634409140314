import React from 'react';
import $ from 'jquery';
require("jquery.ripples");

function Home() {
    $(document).ready(() => {
        $('.waterRipple-effect').ripples({
            resolution: 512,
            dropRadius: 20,
            perturbance: 0.02,
        });
    })

    return (
        <>
            <section id="heading" className='w-100 overflow-hidden'>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12">
                            <div id="headingRipple" className="WaterRipple waterRipple-effect"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className='position-relative mb-lg-5 pb-5'>
                <div className="About-Ripple-Effect position-absolute waterRipple-effect"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto py-lg-5 my-lg-5 position-relative">
                            <div className="py-5 px-2 px-sm-0">
                                <h3 className='about-heading display-4 fw-bolder'>About Us<br /><span className='display-6'>Transforming Air into Life</span></h3>
                                <p className='about-para'>The importance of a sustainable and efficient water supply cannot be overstated. VidaTech is a leading and only subsidiary of Spanish manufacturer of Atmospheric Water Generators M/s GENAQ in Pakistan. Our aim is to contribute to solving water challenges in Pakistan. With a commitment to sustainability and environmental control, VidaTech has established itself as a trusted provider of advanced atmospheric water generators. Our comprehensive range of products caters to diverse applications in Pakistan, including societies, industries, agriculture, and emergency areas in Pakistan.</p>
                                <img src="assets/img/about-delete.png" alt="Vida Tech" className='d-lg-none w-100' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="products">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 m-auto">
                            <div className="row">
                                <h1 className='text-center product-type-heading display-5 fw-bold'>Our Solutions</h1>
                                <p className='text-center product-para mb-5'>Increasing water scarcity, lack of water resources, increasing population, and pollution are major issues in Pakistan.  Our technology, which extracts water from the atmosphere, provides a unique solution to water challenges. It's sustainable, independent, and offers clean water free from typical groundwater contaminants. By introducing this technology, VidaTech is set to redefine the way Pakistan acquires and utilises water, offering hope amidst the impending crisis.</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row mb-5">
                                <h1 className='text-center product-type-heading fs-1 fw-bold'>Commercial</h1>
                                <p className='text-center product-para mb-5 w-75 m-auto product-type-description-para'>VidaTech’s Stratus generators provide purest drinking water avoiding plastic waste and storage space, compared with bottled water dispensers. They can be used at homes, offices, hotels, hospitals, public buildings etc. Now you can make your own pure and chemical free water.</p>
                                <h3 className='text-center fs-3 fw-bold product-type-heading pb-4 product-sub-main-heading'>Stratus by Vida Tech</h3>
                                <div className="col-sm-6 col-lg-4  mb-3 mb-lg-0">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/Stratus-S20 (3).webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Stratus S20</h4>
                                    <p className='product-description text-center'>Up to 20 litres/day</p>
                                </div>
                                <div className="col-sm-6 col-lg-4  mb-3 mb-lg-0">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/stratus-S50.webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Stratus S50</h4>
                                    <p className='product-description text-center'>Up to 50 litres/day</p>
                                </div>
                                <div className="col-sm-6 col-lg-4  mb-3 mb-lg-0 m-auto">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/Stratus-S200.webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Stratus S200</h4>
                                    <p className='product-description text-center'>Up to 200 litres/day</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row mb-5">
                                <h1 className='text-center product-type-heading fs-1 fw-bold'>Industrial</h1>
                                <p className='text-center product-para mb-5 w-75 m-auto product-type-description-para'>Designed to generate the highest quality of water at a reduced cost and without expensive installations. It is an ideal solution for remote locations such as construction sites, mines, oil rigs, etc.</p>
                                <h3 className='text-center fs-3 fw-bold product-type-heading pb-4 product-sub-main-heading'>Nimbus by Vida Tech</h3>
                                <div className="col-sm-6">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/Nimbus-N500.webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Nimbus N500</h4>
                                    <p className='product-description text-center'>Up to 500 litres/day</p>
                                </div>
                                <div className="col-sm-6">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/Nimbus-N4500.webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Nimbus N4500</h4>
                                    <p className='product-description text-center'>Up to 4500 litres/day</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row mb-5">
                                <h1 className='text-center product-type-heading fs-1 fw-bold'>Emergencies</h1>
                                <p className='text-center product-para mb-5 w-75 m-auto product-type-description-para'> Designed for quick deployment of water in emergencies, natural disasters, humanitarian aid, civilian and military camps, etc. To overcome any logistical challenge, they supply safe water wherever they are located.</p>
                                <h3 className='text-center fs-3 fw-bold product-type-heading pb-4 product-sub-main-heading'>Cumulus by Vida Tech</h3>
                                <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/Cumulus C50.webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Cumulus C50</h4>
                                    <p className='product-description text-center'>Up to 50 litres/day</p>
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/Cumulus C500.webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Cumulus C500</h4>
                                    <p className='product-description text-center'>Up to 500 litres/day</p>
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0 m-auto">
                                    <div className="products-img-box w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-3 rounded-4">
                                        <img src="assets/img/Cumulus C5000-CO.webp" alt="Vida Tech" className='product-img' />
                                    </div>
                                    <h4 className='product-name pt-3 fw-bold text-center'>Cumulus C5000</h4>
                                    <p className='product-description text-center'>Up to 5000 litres/day</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section id="why-us" className='w-100 overflow-hidden px-3 px-sm-0'>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="assets/img/why-us-img.webp" alt="Vida Tech" className='w-100 pe-lg-5 h-100' />
                        </div>
                        <div className="col-lg-6 py-5">
                            <h3 className='why-us-heading pb-3'>How does it work?</h3>
                            <p className='why-us-para mb-5 pe-lg-5'> By integrating advanced technologies, our air to water generators optimise water usage, reduce waste, and ensure the highest standards of water quality. The air to water generators intake air from the inlet system, the air passes through several filters, and then condenses water vapours into liquid, after that, the water is again filtered and sterilised. Salts and minerals are added resulting in clean pure drinkable water of PH 7. Major cities in Pakistan have humidity levels exceeding 30%, making them perfect operational grounds for our generators.</p>
                            <h3 className='why-us-heading pb-5'>Our Technology</h3>
                            <p style={{ fontSize: "1.1rem" }}>VidaTech’s atmospheric water generation solution includes several technologies:</p>
                            <div className="application-img-box">
                                <ul style={{ fontSize: "1.1rem" }}>
                                    <li>2-stage air filtration</li>
                                    <li>Condensation chamber in food-grade materials</li>
                                    <li>Efficient refrigeration components and heat exchangers</li>
                                    <li>Water treatment: sediment, activated carbon, zeolite, and ultrafiltration</li>
                                    <li>Mineralization and UV water purification</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="permoting">
                <div className="container py-3">
                    <div className="row py-5">
                        <h1 className='text-center fs-2 fw-bold' style={{ color: "#0a9da7" }}>Promoting Water Sustainability through Innovative Technology</h1>
                        <p className='text-center' style={{ fontSize: "1.1rem" }}>VidaTech's atmospheric water generators are cutting-edge solutions that produce high-quality drinking water from the air. Using humidity and temperature differentials, our machines generate water that is free from bacteria and impurities, with no need for an external water source. This innovative technology is highly efficient, environmentally friendly, and adaptable to diverse climatic conditions, making it an ideal solution for regions grappling with water scarcity.</p>
                        <div className="permoting-img-box d-flex flex-nowrap justify-content-between py-5 overflow-auto">
                            <div className="permoting-img-subBox me-5 d-flex flex-column align-items-center justify-content-around">
                                <img src="assets/img/european-commission.jpg" alt="Vida Tech" className='permoting-img mb-3' />
                                <h6 className='text-center'>European<br />Commission Seal<br />of Excellence</h6>
                            </div>
                            <div className="permoting-img-subBox me-5 d-flex flex-column align-items-center justify-content-around">
                                <img src="assets/img/sgs-iso.jpg" alt="Vida Tech" className='permoting-img mb-3' />
                                <h6 className='text-center'>ISO 9001</h6>
                            </div>
                            <div className="permoting-img-subBox me-5 d-flex flex-column align-items-center justify-content-around">
                                <img src="assets/img/european-union.jpg" alt="Vida Tech" className='permoting-img mb-3' />
                                <h6 className='text-center'>Water Quality</h6>
                            </div>
                            <div className="permoting-img-subBox me-5 d-flex flex-column align-items-center justify-content-around">
                                <img src="assets/img/ce.jpg" alt="Vida Tech" className='permoting-img mb-3' />
                                <h6 className='text-center'>CE Declaration of<br />Conformity</h6>
                            </div>
                            <div className="permoting-img-subBox me-5 d-flex flex-column align-items-center justify-content-around">
                                <img src="assets/img//Climate-chamber-2-GEN03.jpg" alt="Vida Tech" className='permoting-img mb-3' />
                                <h6 className='text-center'>European<br />Tested in Climate<br />Chamber</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
