import React from 'react'

function Footer() {
    return (
        <>
            <footer id='foot'>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-4 col-4 px-3">
                            <img src="assets/img/vida tech logo.webp" alt="Vida Tech" className='foot-logo pb-4' />
                            <p className='foot-para'>VidaTech is a leading and only subsidiary of Spanish manufacturer of Atmospheric Water Generators M/s GENAQ in Pakistan. Our aim is to contribute to solving water challenges in Pakistan. With a commitment to sustainability and environmental control, VidaTech has established itself as a trusted provider of advanced atmospheric water generators.</p>
                        </div>
                        <div className="col-6 col-md-4 text-center footer-nav-col d-flex flex-column justify-content-center align-items-center">
                            <h2 className='pb-2 foot-nav-heading'>Our Pages</h2>
                            <ul className='list-unstyled'>
                                <li className='pb-2'><a href="#heading" className='text-decoration-none text-black foot-nav position-relative'>Home</a></li>
                                <li className='pb-2'><a href="#about" className='text-decoration-none text-black foot-nav position-relative'>About</a></li>
                                <li className='pb-2'><a href="#products" className='text-decoration-none text-black foot-nav position-relative'>Solutions</a></li>
                                <li className='pb-2'><a href="#why-us" className='text-decoration-none text-black foot-nav position-relative'>Technology</a></li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 detail-col d-flex flex-column justify-content-center ">
                            <h2 className='foot-detail-heading pb-2'>Our Details</h2>
                            <h4 className='fs-5'><i className="fa-solid fa-location-dot"></i> Mailing Address</h4>
                            <p className='text-black'>Plot # 298, Street # 03, I-9/3 Islamabad, Islamabad</p>
                            <h4 className='fs-5'><i className="fa-solid fa-phone"></i> Phone Number</h4>
                            <p className='text-black'>+92 341 9000008</p>
                            <h4 className='fs-5'><i className="fa-solid fa-envelope"></i> Email Address</h4>
                            <p className='text-black'>info@vidatech.pk</p>
                        </div>
                    </div>
                </div>
            </footer>
            <section id="powered">
                <h4 className='text-center powered-heading mb-0 py-3'>&copy; Vida Tech 2023-2024 | All Right Reserved | Designed By <a href="https://www.linkedin.com/company/creactiveinc/" className='text-decoration-none'>Creactive</a></h4>
            </section>
        </>
    )
}

export default Footer