import React from 'react'
import { closeNav } from './script';

function Header() {
    return (
        <header id='head'>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <a className="navbar-brand" href="/"><img src="assets/img/vida tech logo.webp" alt="Orion Energy" className='orion-logo' /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto px-3 px-sm-0">
                            <li className="nav-item">
                                <a className="nav-link active text-black nav-link" aria-current="page" href="#heading" onClick={closeNav}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-black nav-link" href="#about" onClick={closeNav}>About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-black nav-link" href="#products" onClick={closeNav}>Solutions</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-black nav-link" href="#why-us" onClick={closeNav}>Technology</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
